import boatsetterPaths from '../../utils/boatsetterPaths';

export const howWorksData = {
  Renter: [
    {
      title: 'Search',
      text: 'Explore our privately owned, worldwide fleet',
      icon: '/images/homepage/how/search.svg',
    },
    {
      title: 'Connect',
      text: 'Contact the boat owner and captain',
      icon: '/images/homepage/how/connect.svg',
    },
    {
      title: 'Book',
      text: 'Choose your date, reserve your boat',
      icon: '/images/homepage/how/book.svg',
    },
    {
      title: 'Cruise',
      text: 'Grab your crew and have a great time out on the water!',
      icon: '/images/homepage/how/cruise.svg',
    },
  ],
  Owner: [
    {
      title: 'List',
      text: 'Set your availability, pricing and more',
      icon: '/images/homepage/how/search.svg',
    },
    {
      title: 'Connect',
      text: 'Review booking requests and inquiries',
      icon: '/images/homepage/how/connect.svg',
    },
    {
      title: 'Manage',
      text: 'Easily handle all of your bookings in our Owner’s app',
      icon: '/images/homepage/how/book.svg',
    },
    { title: 'Collect', text: 'Watch your bank account grow', icon: '/images/homepage/how/cruise.svg' },
  ],
  Captain: [
    {
      title: 'Join',
      text: 'Build your digital profile and select boats to captain',
      icon: '/images/homepage/how/search.svg',
    },
    {
      title: 'Connect',
      text: 'Review booking requests from renters',
      icon: '/images/homepage/how/connect.svg',
    },
    {
      title: 'Manage',
      text: 'Oversee all your bookings',
      icon: '/images/homepage/how/book.svg',
    },
    {
      title: 'Cruise & collect',
      text: 'Get out on the water and watch your bank account grow',
      icon: '/images/homepage/how/cruise.svg',
    },
  ],
};

export const experiencesData = [
  [
    {
      title: 'Fishing charters',
      image: '/images/homepage/experiences/Fishing.webp',
      imageJPG: '/images/homepage/experiences/Fishing.jpeg',
      link: boatsetterPaths.fishingCharters,
      grid: 'sailing',
    },
    {
      title: 'Sailing',
      image: '/images/homepage/experiences/Sailing.webp',
      imageJPG: '/images/homepage/experiences/Sailing.jpg',
      link: boatsetterPaths.sailboatRentals,
      grid: 'sailing',
      objectPosition: '40%',
      mobileObjectPosition: '0',
    },
  ],
  [
    {
      title: 'Pontoon boats',
      image: '/images/homepage/experiences/Pontoons.webp',
      imageJPG: '/images/homepage/experiences/Pontoons.jpg',
      link: boatsetterPaths.pontoonBoatRentals,
      grid: 'pontoon',
      className: 'long',
      mobileObjectPosition: '35%',
    },
    {
      title: 'Boat rentals',
      link: boatsetterPaths.boatRentals,
      image: '',
      imageJPG: '/images/homepage/experiences/boat_rentals.jpeg',
      grid: 'pontoon',
      className: 'long',
    },
  ],
  [
    {
      title: 'Bachelor party',
      image: '/images/homepage/experiences/Bachelors.webp',
      imageJPG: '/images/homepage/experiences/Bachelors.jpg',
      link: boatsetterPaths.bachelorPartyBoatRentals,
      grid: 'bachelor',
      objectPosition: '90%',
      mobileObjectPosition: '85%',
    },
    {
      title: 'Bachelorette party',
      link: boatsetterPaths.bachelorettePartyBoatRentals,
      image: '/images/homepage/experiences/Bachelorette.webp',
      imageJPG: '/images/homepage/experiences/Bachelorette.jpg',
      grid: 'bachelor',
      objectPosition: '20%',
      mobileObjectPosition: '80%',
    },
  ],
  [
    {
      title: 'Party boats',
      image: '/images/homepage/experiences/PartyBoat.webp',
      imageJPG: '/images/homepage/experiences/PartyBoat.jpg',
      link: boatsetterPaths.partyBoatRentals,
      grid: 'bachelorrete',
    },
    {
      title: 'Luxury yacht charters',
      image: '/images/homepage/experiences/Lux.webp',
      imageJPG: '/images/homepage/experiences/Lux.jpg',
      link: boatsetterPaths.luxuryYachtCharters,
      grid: 'bachelorrete',
      mobileObjectPosition: '25%',
    },
  ],
  [
    {
      title: 'Yachts rentals',
      image: '/images/homepage/experiences/Yachts.webp',
      imageJPG: '/images/homepage/experiences/Yachts.jpg',
      link: boatsetterPaths.yachtRentals,
      grid: 'speed',
      className: 'long',
    },
    {
      title: 'Cruising',
      link: boatsetterPaths.boatRentals,
      image: '/images/homepage/experiences/Cruising.webp',
      imageJPG: '/images/homepage/experiences/Cruising.jpg',
      grid: 'speed',
      className: 'long',
      mobileObjectPosition: '70%',
    },
  ],
];

export const reviewsData = [
  {
    name: 'Ashlee',
    review: 'Absolutely loved the boat. It was very spacious and the crew was amazing. Will definitely book again!',
    boat: '/images/homepage/reviews/ashlee/boat.jpg',
    profile: '/images/homepage/reviews/ashlee/profile.jpg',
  },
  {
    name: 'Skylar',
    review:
      'Great boat for the price! Jiri was helpful, patient, and flexible! Also the marina was in a prime location. 10/10 would rent again!',
    boat: '/images/homepage/reviews/skylar/boat.jpg',
    profile: '/images/homepage/reviews/skylar/profile.jpg',
  },
  {
    name: 'Jennifer',
    review:
      'Amazing experience! We had such a blast and Joe was fantastic. Couldn’t recommend more! 100% will book again!',
    boat: '/images/homepage/reviews/jennifer/boat.jpg',
    profile: '/images/homepage/reviews/jennifer/profile.jpg',
  },
  {
    name: 'Michael',
    review:
      'Boat was extremely clean, very spacious and the speaker system was great. Definitely will be renting this boat again!',
    boat: '/images/homepage/reviews/michael/boat.jpg',
    profile: '/images/homepage/reviews/michael/profile.jpg',
  },
  {
    name: 'Brandon',
    review: 'Great experience. Lindsay was friendly and helpful. We had a fantastic time. Would rent again.',
    boat: '/images/homepage/reviews/brandon/boat.jpg',
    profile: '/images/homepage/reviews/brandon/profile.jpg',
  },
  {
    name: 'Capri',
    review: 'I had the most amazing time ever! I would give ten stars if I could.',
    boat: '/images/homepage/reviews/capri/boat.jpg',
    profile: '/images/homepage/reviews/capri/profile.jpg',
  },
];

export const quotesData = {
  twp: 'The next wave of the sharing economy has arrived...',
  cnbc: 'Boat owners can now offset their entire cost of boat ownership by renting their boats in as few as one to two times a month.',
  twsj: 'Boatsetter makes it easy to book an afternoon sail on a whim...',
  tc: 'Boats have even more potential than homes for the sharing economy. With a few rentals a month, most boats can pay for themselves.',
  cnn: 'Boatsetter makes it simpler for folks to spend time on the water.',
  buzz: 'Boatsetter is a one-stop shop for boat renters...and allows boat owners to offset associated ownership costs.',
};
